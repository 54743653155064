/**
 * The HeroSlideVehicles component
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import ResponsivePicture from "../../ResponsivePicture";

/**
 * Stateless react component to render the HeroSlideVehicles page
 * @param {Object} props the props passed to the page.
 */
const HeroSlideVehicles = (props, context) => {
  const { lang } = props;

  return (
    <div className="hero hero-carousel-slide">
      <div className="hero__bg">
        <ResponsivePicture
          cover
          mediumData={{
            sources: [
              {
                url: "https://cdn.myfolie.de/media/header_4_demo.png",
                media: "(max-width: 820px)",
                content_type: "image/jpeg",
              },
              {
                url: "https://cdn.myfolie.de/media/header_4_demo.png",
                media: "(min-width: 821px)",
                content_type: "image/jpeg",
              },
            ],
            metadata: {
              width: "100%",
              height: "100%",
            },
          }}
          altTitle={context.t(
            "Order car stickers and vehicle lettering online."
          )}
        />
      </div>
      <Container className="hero-carousel-slide--content">
        <Row>
          <Col lg={6}>
            <h1>
              {context.t("Order car stickers and vehicle lettering online.")}
            </h1>
            <p>
              {context.t(
                "Advertise or style your car with custom car stickers and lettering. Design and order online now!"
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 4 }} xl={{ size: 3 }} className="mt-2">
            <Link
              to={`/${lang}/klebeschriften/`}
              className="btn btn-success btn-block"
            >
              {context.t("Design Adhesive Lettering")}
            </Link>
          </Col>
          <Col lg={{ size: 4 }} xl={{ size: 3 }} className="mt-2">
            <Link
              to={`/${lang}/i/sticker-vehicle-own-design/`}
              className="btn btn-success btn-block"
            >
              {context.t("Upload File")}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
HeroSlideVehicles.contextTypes = {
  t: PropTypes.func,
};
export default HeroSlideVehicles;
