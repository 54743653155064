/**
 * The Hero component
 */
import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import HeroSlideShopWindow from "./utils/HeroSlideShopWindow";
import HeroSlideVehicles from "./utils/HeroSlideVehicles";
import HeroSlideSticker from "./utils/HeroSlideSticker";
import HeroSlideWalltattoo from "./utils/HeroSlideWalltattoo";

/**
 * Stateless react component to render the Hero page
 * @param {Object} props the props passed to the page.
 */
const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 70,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    partialVisibilityGutter: 70,
  },
};
const Hero = ({ lang }, context) => {
  return (
    <div
      className="container"
      style={{
        paddingBottom: "30px",
        position: "relative",
      }}
    >
      <Carousel
        responsive={responsive}
        autoPlay
        autoPlaySpeed={4000}
        infinite
        keyBoardControl
        shouldResetAutoplay
        swipeable
        draggable
        pauseOnHover
        partialVisbile
        showDots
        renderDotsOutside
        ssr
      >
        <HeroSlideVehicles lang={lang} />
        <HeroSlideShopWindow lang={lang} />
        <HeroSlideWalltattoo lang={lang} />
        <HeroSlideSticker lang={lang} />
      </Carousel>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
Hero.contextTypes = {
  t: PropTypes.func,
  lang: PropTypes.string,
};

export default Hero;
