/**
 * The HeroSlideSticker component
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import ResponsivePicture from "../../ResponsivePicture";

/**
 * Stateless react component to render the HeroSlideSticker page
 * @param {Object} props the props passed to the page.
 */
const HeroSlideSticker = (props, context) => {
  const { lang } = props;

  return (
    <div className="hero hero-carousel-slide">
      <div className="hero__bg">
        <div className="bg-dark-fadeout-left"></div>
        <ResponsivePicture
          cover
          mediumData={{
            sources: [
              {
                url: "https://cdn.myfolie.de/media/hero-sticker-square-min.jpg",
                media: "(max-width: 820px)",
                content_type: "image/jpeg",
              },
              {
                url: "https://cdn.myfolie.de/media/hero-sticker-min.jpg",
                media: "(min-width: 821px)",
                content_type: "image/jpeg",
              },
            ],
            metadata: {
              width: "100%",
              height: "100%",
            },
          }}
          altTitle={context.t("Stickers")}
          className="h-100"
        />
      </div>
      <Container className="hero-carousel-slide--content text-center text-white">
        <Row>
          <Col lg={{ size: 5, offset: 7 }}>
            <h2 className="h1">{context.t("Stickers & Adhesives")}</h2>
            <p>
              {context.t(
                "Create your own custom stickers—for fun, friends, clubs, company branding, or celebrating your idols! Perfect for every occasion!"
              )}
            </p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col lg={{ size: 3, offset: 8 }} className="mt-2">
            <Link
              to={`/${lang}/t/stickers/`}
              className="btn btn-success btn-block"
            >
              {context.t("Sticker with your Design")}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
HeroSlideSticker.contextTypes = {
  t: PropTypes.func,
};
export default HeroSlideSticker;
