/**
 * The About component
 */
import React, { useState } from "react";
import Carousel from "react-multi-carousel";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
/**
 * Stateless react component to render the About page
 * @param {Object} props the props passed to the page.
 */
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 3000 },
    items: 4,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const HighlightProducts = (props, context) => {
  const [activeTab, setActiveTab] = useState(0);
  // const handleChangeIndex = (index) => {
  //   setActiveTab(index);
  // };

  const { language } = useSelector((state) => {
    return {
      language: state.i18nState.lang,
    };
  });

  const tabs = [
    {
      title: context.t("Vehicles"),
      content: [
        {
          title: context.t("Individual Print"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_individual_print_product_shot.png",
          link: "/i/sticker-vehicle-own-design/",
          price: context.t("from") + " 5,99€",
          description: context.t(
            "Custom print stickers for your car, showcasing your unique design in high-quality, durable material."
          ),
        },
        {
          title: context.t("rear window sticker"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_car_rear_window_product_shot.png",
          link: "/p/heckscheibenaufkleber/",
          price: context.t("from") + " 3,79€",
          description: context.t(
            "Personalized rear window stickers, perfect for adding a personal touch to your vehicle."
          ),
        },
        {
          title: context.t("Vehicle Labeling"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_vehicle_lettering_product_shot.png",
          link: "/p/fahrzeugbeschriftung/",
          price: context.t("from") + " 4,95€",
          description: context.t(
            "Custom Lettering: Choose out of 80+ Fonts and 120+ Colors with your personalized Text."
          ),
        },
        {
          title: context.t("magnetic foil"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_magnetic_film_product_shot_alt.png",
          link: "/i/magnetic-car-stickers-for-companies/",
          price: context.t("from") + " 19,79€",
          description: context.t(
            "Magnetic stickers for cars, easily removable and customizable to suit any occasion or branding needs."
          ),
        },
        {
          title: context.t("perforated foil"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_perforated_foil_product_shop_green.png",
          link: "/i/perforated-window-film-rear-window-car/",
          price: context.t("from") + " 6,99€",
          description: context.t(
            "Perforated car stickers allow visibility from inside, ideal for creative and branded designs."
          ),
        },
        {
          title: context.t("Car sticker"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_car_sticker_product_shot.png",
          link: "/i/sticker-with-own-logo-car/",
          price: context.t("from") + " 5,99€",
          description: context.t(
            "Durable car stickers that can be customized with any design, perfect for personal or business use."
          ),
        },
        {
          title: context.t("Boat Labeling"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_boat_labeling_product_shot.png",
          link: "/t/vehicle-decals/boat-labeling/",
          price: context.t("from") + " 4,95€",
          description: context.t(
            "Create custom boat labels with durable adhesive stickers, ideal for identification or decoration."
          ),
        },
        {
          title: context.t("Car Wrapping Film"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_car_wrapping_product_shot.png",
          link: "/t/vehicle-decals/car-film/car-wrapping/",
          price: context.t("from") + " 34,99€",
          description: context.t(
            "High-quality wrapping film for cars, allowing you to completely change the look with custom designs."
          ),
        },
      ],
    },
    {
      title: context.t("Shop Window"),
      content: [
        {
          title: context.t("Lettering"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_window_lettering_product_shot.png",
          link: "/klebeschriften/",
          price: context.t("from") + " 4,95€",
          description: context.t(
            "Create eye-catching window lettering for your shop with customizable fonts and colors."
          ),
        },
        {
          title: context.t("Individual Print"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_individual_print_product_shot.png",
          link: "/i/window-film-your-design/",
          price: context.t("from") + " 3,79€",
          description: context.t(
            "Custom print stickers for shop windows, ideal for promotions or adding a personal touch to your storefront."
          ),
        },
        {
          title: context.t("Privacy Film with Print"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_privacy_film_logoprint_product_shot.png",
          link: "/i/frosted-glass-film-with-your-logo/",
          price: context.t("from") + " 5,99€",
          description: context.t(
            "Enhance privacy with printed films for windows, offering both functional and aesthetic benefits."
          ),
        },
        {
          title: context.t("Logo Sticker"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_logo_sticker_product_shot.png",
          link: "/i/sticker-with-own-logo-shop-window/",
          price: context.t("from") + " 3,79€",
          description: context.t(
            "Custom logo stickers for branding on windows, vehicles, or any smooth surface."
          ),
        },
        {
          title: context.t("Whiteprint"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_whiteprint_product_shot_rocket.png",
          link: "/i/window-sticker-with-white-print/",
          price: context.t("from") + " 5,99€",
          description: context.t(
            "Whiteprint stickers for bold and clean designs, perfect for making statements on any surface."
          ),
        },
        {
          title: context.t("Promotional Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_window_sales_product_shot.png",
          link: "/t/shop-window-stickers/promotional-stickers/sale-and-discount-stickers/",
          price: context.t("from") + " 6,49€",
          description: context.t(
            "Promotional stickers to highlight sales and special offers, customizable to fit any campaign."
          ),
        },
        {
          title: context.t("Frosted Glass Film"),

          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_frosted_glass_product_shot.png",
          link: "/t/shop-window-stickers/decorative-films/frosted-glass-film/",
          price: context.t("from") + " 5,99€",
          description: context.t(
            "Frosted glass films for windows, offering privacy while adding a stylish, etched-glass look."
          ),
        },
        {
          title: context.t("Seasonal Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_seasonal_stickers_product_shot.png",
          link: "/t/shop-window-stickers/promotional-stickers/event-and-seasonal-promotions/",
          price: context.t("from") + " 6,90€",
          description: context.t(
            "Seasonal stickers perfect for holiday promotions and seasonal decoration, easy to apply and remove."
          ),
        },
      ],
    },
    {
      title: context.t("Stickers & Adhesives"),
      content: [
        {
          title: context.t("Rectangular Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_sticker_rectangular_product_shot.png",
          link: "/i/sticker-with-rectangular-cut/",
          price:
            "50 " + context.t("pieces") + " " + context.t("from") + " 20,00€",
          description: context.t(
            "Rectangular stickers in bulk, customizable with any design, suitable for branding and personal use."
          ),
        },
        {
          title: context.t("Circular Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_sticker_circular_product_shot.png",
          link: "/i/sticker-with-circle-cut/",
          price:
            "50 " + context.t("pieces") + " " + context.t("from") + " 20,00€",
          description: context.t(
            "Circular stickers available in bulk, ideal for logos, labels, and creative designs."
          ),
        },
        {
          title: context.t("Die Cut Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_sticker_die_cut_product_shop.png",
          link: "/i/sticker-with-die-cut/",
          price:
            "50 " + context.t("pieces") + " " + context.t("from") + " 27,00€",
          description: context.t(
            "Die cut stickers that match the shape of your design, providing a professional and polished look."
          ),
        },
        {
          title: context.t("Big Stickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_individual_adhesive_wall_product_shot.png",
          link: "/eigenes-motiv/",
          price: context.t("from") + " 3,79€",
          description: context.t(
            "Large format stickers for walls and windows, perfect for impactful messages and branding."
          ),
        },
      ],
    },
    {
      title: context.t("Floor Stickers"),
      content: [
        {
          title: context.t("Outdoor Floorstickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_outdoor_floor_sticker_product_shot.png",
          link: "/i/floor-sticker/",
          price: context.t("from") + " 9,89€",
          description: context.t(
            "Durable floor stickers designed for outdoor use, perfect for guiding customers or promoting events."
          ),
        },
        {
          title: context.t("Indoor Floorstickers"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_floor_sticker_indoor_product_shot.png",
          link: "/i/floor-sticker-indoor/",
          price: context.t("from") + " 8,99€",
          description: context.t(
            "Indoor floor stickers, customizable for safety, directions, or promotional purposes."
          ),
        },
        {
          title: context.t("Keep Distance"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_floor_sticker_keep_distance_product_shot.png",
          link: "/t/floor-stickers/keep-distance/",
          price: context.t("from") + " 11,20€",
          description: context.t(
            "Floor stickers to encourage social distancing, customizable with various designs and messages."
          ),
        },
        {
          title: context.t("Sale & Promotion"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_floor_sticker_sales_promotion_product_shot.png",
          link: "/t/floor-stickers/sale-and-promotion/",
          price: context.t("from") + " 8,99€",
          description: context.t(
            "Promotional floor stickers for sales events, drawing attention to offers and products."
          ),
        },
      ],
    },
    {
      title: context.t("Home Decor & Walltattoo"),
      content: [
        {
          title: context.t("Single Colored Furnitur Film"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_singlecolor_furniture_film_product_shot.png",
          link: "/t/foils-and-adhesive-films/furniture-film/solid-color-adhesive-film/",
          price: context.t("from") + " 2,90€",
          description: context.t(
            "Single-colored adhesive films for furniture, perfect for refreshing and protecting surfaces."
          ),
        },
        {
          title: context.t("Patterned Colored Furnitur Film"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_patterened_furnitur_film_product_shot.png",
          link: "/t/foils-and-adhesive-films/furniture-film/patterned-adhesive-film/",
          price: context.t("from") + " 4,90€",
          description: context.t(
            "Patterned films for furniture, offering decorative and protective solutions for any style."
          ),
        },
        {
          title: context.t("Your Design as a Wall Decal"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_personalized_walltatto_product_shot.png",
          link: "/eigenes-motiv/",
          price: context.t("from") + " 4,95€",
          description: context.t(
            "Custom wall decal – your unique design printed on adhesive material, available in any size and shape."
          ),
        },
        {
          title: context.t("Personalized Lettering"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_wallart_personalized_lettering_product_shot.png",
          link: "/i/klebebuchstaben/",
          price: context.t("from") + " 4,95€",
          description: context.t(
            "Create personalized lettering stickers for walls, windows, or vehicles, available in numerous fonts and colors."
          ),
        },
        {
          title: context.t("Individual adhesive size"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_vinyl_individual_cut_product_shop.png",
          link: "/folienzuschnitt/",
          price: context.t("from") + " 11,50€",
          description: context.t(
            "Custom-sized adhesive films for any application, perfect for bespoke projects."
          ),
        },
        {
          title: context.t("Walltattoo Designer Collection"),
          image:
            "https://cdn.myfolie.de/media/products/standalone/demo_walltattoo_designer_collection_product_shot.png",
          link: "/t/walltattoo/designers-walltattoo-collection/",
          price: context.t("from") + " 8,80€",
          description: context.t(
            "Designer wall tattoos from exclusive collections, adding unique artistic touches to any space."
          ),
        },
      ],
    },
  ];

  return (
    <Container>
      <Row>
        <h2 className="h3 text-center d-block w-100">
          {context.t("Our top products at a glance")}
        </h2>
        <Col md={{ offset: 1, size: 10 }}>
          <Nav
            tabs
            style={{ border: 0 }}
            className="px-5 py-2 highlight-product--tabs"
          >
            {tabs.map((tab, index) => (
              <NavItem style={{ margin: "auto auto" }}>
                <NavLink
                  style={{
                    border: 0,
                    borderBottom: index === activeTab ? "3px solid #169dce" : 0,
                  }}
                  className={`text-dark py-0 text-bold ${
                    index === activeTab ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          {tabs.map((tab, index) => (
            <TabContent activeTab={activeTab}>
              <TabPane tabId={index} style={{ minHeight: "200px" }}>
                <Carousel
                  slidesToSlide={1}
                  partialVisible
                  responsive={responsive}
                  autoPlay
                  autoPlaySpeed={8000}
                  infinite
                  keyBoardControl
                  shouldResetAutoplay
                  swipeable
                  draggable
                  pauseOnHover
                  partialVisbile
                  ssr
                >
                  {tab?.content?.map((item, i) => (
                    <div className="m-4" key={i}>
                      <Link
                        className="hover-scale-img"
                        to={"/" + language + item.link}
                        style={{ color: "#000" }}
                      >
                        <div
                          className="d-block bg-light p-2 mb-2"
                          style={{
                            height: "200px",
                            borderRadius: "1rem",
                            overflow: "hidden",
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={item.image}
                            alt={item.title}
                            style={{
                              maxWidth: "90%",
                              maxHeight: "90%",
                              margin: "auto",
                            }}
                          />
                        </div>
                        <span className="d-block h5">{item.title}</span>
                        <p className="text-muted">{item.description}</p>
                        <span className="d-block h6 text-success">
                          {item.price}
                        </span>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </TabPane>
            </TabContent>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

/**  define proptype for the 'translation' function  */
HighlightProducts.contextTypes = {
  t: PropTypes.func,
};

export default HighlightProducts;
