/**
 * The HeroSlideWalltattoo component
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import ResponsivePicture from "../../ResponsivePicture";

/**
 * Stateless react component to render the HeroSlideWalltattoo page
 * @param {Object} props the props passed to the page.
 */
const HeroSlideWalltattoo = (props, context) => {
  const { lang } = props;

  return (
    <div className="hero hero-carousel-slide">
      <div className="hero__bg">
        <ResponsivePicture
          cover
          mediumData={{
            sources: [
              {
                url: "https://cdn.myfolie.de/media/hero-wallart-square-min.jpg",
                media: "(max-width: 820px)",
                content_type: "image/jpeg",
              },
              {
                url: "https://cdn.myfolie.de/media/hero-wallart-min.jpg",
                media: "(min-width: 821px)",
                content_type: "image/jpeg",
              },
            ],
            metadata: {
              width: "100%",
              height: "100%",
            },
          }}
          altTitle={context.t("Walltattoos")}
        />
      </div>
      <Container className="hero-carousel-slide--content text-md-white">
        <Row>
          <Col lg={6}>
            <h2 className="h1">{context.t("Walltattoos & Photoprint")}</h2>
            <p>
              {context.t(
                "Explore custom wall art options like murals, wallpapers, canvases, posters, and personalized wall tattoos, all crafted for quality and a perfect fit."
              )}
            </p>
          </Col>
        </Row>
        <Row className="hero__content__ctas">
          <Col lg={{ size: 4 }} xl={{ size: 3 }} className="mt-2">
            <Link
              to={`/${lang}/t/photo-murals-and-wallpaper/`}
              className="btn btn-success btn-block"
            >
              {context.t("Custom Photo Prints")}
            </Link>
          </Col>
          <Col lg={{ size: 4 }} xl={{ size: 3 }} className="mt-2">
            <Link
              to={`/${lang}/t/walltattoo/personalized-walltattoo/`}
              className="btn btn-success btn-block"
            >
              {context.t("Personalized Walltattoo")}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
HeroSlideWalltattoo.contextTypes = {
  t: PropTypes.func,
};
export default HeroSlideWalltattoo;
